<template>
    <div class="novoglow-header">
        <div class="novoglow-subtitle">Data from novoGloW</div>
        <div v-if="loading" class="novoglow-text novoglow-loading">Updating</div>
        <div v-else class="novoglow-text">{{ lastUpdated.text }}</div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useNovoGlow } from '@dview/logic'

export default defineComponent({
    setup() {
        const { loading, loaded, lastUpdated } = useNovoGlow()

        return {
            loading,
            loaded,
            lastUpdated
        }
    }
})
</script>

<style scoped lang="scss">
.novoglow-header {
    height: 27px;
    text-align: center;

    .novoglow-subtitle {
        @include typography-label;
    }

    .novoglow-text {
        @include typography-body;
        font-weight: bold;
        line-height: 1em;
        padding-top: 3px;

        &.novoglow-loading {
            font-weight: normal;
        }
    }
}

.novoglow-loading {
    animation: pulsate 1.5s ease-out;
    animation-iteration-count: infinite; 
    opacity: 0.25;
}

@keyframes pulsate {
    0% { 
        opacity: 0.25;
    }
    50% { 
        opacity: 0.75;
    }
    100% { 
        opacity: 0.25;
    }
}
</style>