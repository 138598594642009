
import { defineComponent, ref } from 'vue'
import { filterOutline } from 'ionicons/icons'
import { DeviationType } from '@dview/shared/models'
import DeviationsFilter, { DeviationsFilterComponentApi } from './DeviationsFilter.vue'
import { useDeviationsFilterConfig } from '@dview/logic'

export default defineComponent({
    inheritAttrs: false,
    components: {
        DeviationsFilter
    },
    props: {
        deviationType: {
            type: String as () => DeviationType,
            required: true,
            validator: (value: DeviationType) => ['open', 'hoc-rated', 'closed'].includes(value),
        },
    },
    setup(props) {
        const { active } = useDeviationsFilterConfig(props.deviationType)
        const filterComponent = ref<DeviationsFilterComponentApi>()

        function openDeviationFilter(event: Event) {
            filterComponent.value?.open(event)
        }

        return {
            filterActive: active,
            filterIcon: filterOutline,
            filterComponent,
            openDeviationFilter,
        }
    }
})
