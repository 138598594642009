
import { defineComponent, ref, Ref, computed } from 'vue'
import { checkmarkOutline, arrowDownOutline, arrowUpOutline, closeOutline } from 'ionicons/icons'
import { useDeviationsFilterConfig, DeviationSortingKey, DeviationFilteringKey } from '@dview/logic'
import { DeviationType } from '@dview/shared/models'
import { useAppInsights } from '@dview/shared/util'

export default defineComponent({
    props: {
        deviationType: {
            type: String as () => DeviationType,
            required: true,
            validator: (value: DeviationType) => ['open', 'hoc-rated', 'closed'].includes(value),
        },
    },
    setup(props) {
        const { sortDirection, sortKey, setSortingDirection, setSortingKey, filterKey, setFilterKey } = useDeviationsFilterConfig(props.deviationType)
        const { filterItems, sortItems } = useFilterSortItems(props.deviationType, sortKey, filterKey)
        const { trackEvent } = useAppInsights()

        const isOpen = ref(false)
        const domEvent = ref<Event>(null!)

        function open(event: Event) {
            trackFilterEvent('Opened')

            domEvent.value = event
            isOpen.value = true

            return Promise.resolve()
        }

        function selectSortBy(newSortKey: DeviationSortingKey) {
            if (newSortKey === sortKey.value) {
                toggleSortingDirection()
            } else {
                setSortingKey(newSortKey)
                trackFilterEvent('Selected sort key', { sortKey: newSortKey })
            }
        }

        function selectFilter(newFilterKey: DeviationFilteringKey) {
           if (newFilterKey === filterKey.value) {
                setFilterKey(null!)
                trackFilterEvent('Deselected filter key', { filterKey: newFilterKey })
            } else {
                setFilterKey(newFilterKey)
                trackFilterEvent('Selected filter key', { filterKey: newFilterKey })
            }
        }

        function toggleSortingDirection() {
            const ascending = sortDirection.value === 'ascending'
            setSortingDirection(ascending ? 'descending' : 'ascending')
            trackFilterEvent('Changed sort order', { sortOrder: sortDirection.value, sortKey: sortKey.value })
        }

        function resetFilter() {
            setFilterKey(null!)
            trackFilterEvent('Cleared filter')
        }

        function trackFilterEvent(eventName: string, properties = {}) {
            trackEvent({
                name: `Deviations filter -> ${eventName}`,
                properties: {
                    deviationType: props.deviationType,
                    ...properties
                }
            })
        }

        function close() {
            domEvent.value = null!
            isOpen.value = false
        }

        return {
            // icons
            checkmarkIcon: checkmarkOutline,
            resetFilterIcon: closeOutline,
            sortDirectionIcon: computed(() => sortDirection.value === 'ascending' ? arrowUpOutline : arrowDownOutline),

            // misc. 
            isOpen,
            domEvent,

            // filter data
            sortDirection,
            sortItems,
            filterItems,

            // methods
            close,
            toggleSortingDirection,
            selectSortBy,
            selectFilter,
            resetFilter,

            // part of public accessible api
            open
        } as DeviationsFilterComponentApi
    }
})

/**
 * Generates the selectable sorting / filter "menu items" to display in the popover UI element.
 */
function useFilterSortItems(type: DeviationType, activeSortKey: Ref<DeviationSortingKey>, activeFilterKey: Ref<DeviationFilteringKey>) {
    const filterItems = ref<FilterSortItem[]>([])
    const sortItems = ref<FilterSortItem[]>([])

    function generateSortItem(name: string, key: DeviationSortingKey): FilterSortItem {
        const selected = computed(() => activeSortKey.value === key) as any
        return {
            name,
            key,
            selected
        }
    }

    function generateFilterItem(name: string, key: DeviationFilteringKey): FilterSortItem {
        const selected = computed(() => activeFilterKey.value === key) as any
        return {
            name,
            key,
            selected
        }
    }

    if (type === 'open') {
        sortItems.value.push(generateSortItem('Created Date', 'date'))
        sortItems.value.push(generateSortItem('Flagged', 'flagged'))
        sortItems.value.push(generateSortItem('Classification', 'classification'))
        sortItems.value.push(generateSortItem('Phase', 'phase'))

        filterItems.value.push(generateFilterItem('Only Major', 'major'))
        filterItems.value.push(generateFilterItem('Only Minor', 'minor'))
        filterItems.value.push(generateFilterItem('Only EM', 'environmental-monitoring'))
        filterItems.value.push(generateFilterItem('Lead Time > 25 days', 'leadtime'))
        filterItems.value.push(generateFilterItem('Batch Impact', 'batch'))
    }

    if (type === 'hoc-rated') {
        sortItems.value.push(generateSortItem('Rated Date', 'date'))
        sortItems.value.push(generateSortItem('Rating', 'hoc-rating'))

        filterItems.value.push(generateFilterItem('Only Low Rated', 'hoc-low-rated'))
        filterItems.value.push(generateFilterItem('Only High Rated', 'hoc-high-rated'))
        filterItems.value.push(generateFilterItem('Only EM', 'environmental-monitoring'))
    }

    return {
        filterItems,
        sortItems
    }
}

interface FilterSortItem {
    name: string
    selected: boolean
    key: DeviationSortingKey | DeviationFilteringKey
}

/**
 * This interface is to be used by parent components referring to this component using a ref instance,
 * in order to have a type safe way to call methods on this component.
 */
export interface DeviationsFilterComponentApi {
    open: (event: Event) => Promise<void>
    [index: string]: any // to avoid Vetur template complaints..
}
