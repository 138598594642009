import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-650e5178"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "filter-active-indicator"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_DeviationsFilter = _resolveComponent("DeviationsFilter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_button, _mergeProps({
      onClick: _ctx.openDeviationFilter,
      size: "large"
    }, _ctx.$attrs), {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, { icon: _ctx.filterIcon }, null, 8, ["icon"]),
        (_ctx.filterActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 16, ["onClick"]),
    _createVNode(_component_DeviationsFilter, {
      deviationType: _ctx.deviationType,
      ref: "filterComponent"
    }, null, 8, ["deviationType"])
  ], 64))
}